.ArmyBot {
  display: inline-block;
  text-align: center;
  border: 2px solid black;
  margin-bottom: 25px;
  margin-right: 20px;
  width: 200px;
}

.ArmyBotCard__Header {
  padding: 5px 5px;
  border-bottom: 2px solid black;
}

.ArmyBotCard__Header p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
}

.BotCard__Footer {
  padding: 5px 5px;
}

.BotCard__Footer p {
  padding: 5px 5px;
  margin: 0px;
}
