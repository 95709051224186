@import '../../../styles/variables';

.BotDetail {
  padding-top: 50px;
  &__Info {
    text-align: left;
    padding-left: 25px;
    flex-basis: 50%;
    @media (max-width: $tablet) {
      flex-basis: 100%;
      padding-left: 0px;
    }
  }
  &__Verification-Badge {
    color: red;
  }
  &__Actions {
    width: 100%;
    border: 2px solid black;
    display: flex;
    flex-wrap: wrap;
    &--Support {
      width: calc(50% - 50px);
      padding: 25px;
      border-bottom: 2px solid black;
      border-right: 2px solid black;
      @media (max-width: $tablet) {
        border-right: 0px solid transparent;
        width: 100%;
      }
    }
    &--Buidl {
      width: calc(50% - 52px);
      padding: 25px;
      border-bottom: 2px solid black;
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
    &--Owner {
      width: 100%;
      padding: 25px;
    }
    button {
      width: 100%;
    }
  }
  a {
    text-decoration: underline;
  }
}

.BotDetail {
  &__Card .HatchButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    button { padding: 25px!important; width: 100%; }
  }
  &__Info .HatchButton {
    position: relative;
    button { padding: 25px!important; width: 100%; max-width: 180px; }
  }
  .Ancestry {
    a {
      display: inline-block;
      margin-right: 10px;
    }
    margin-bottom: 25px;
  }
}
