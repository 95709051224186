@import '../../../styles/variables';

.Hero {
  background-color: #000;
  padding-top: 75px;
  padding-bottom: 75px;
  color: white;
  .Columns__Column--50 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.Featured__Intro {
  a { display: block; width: 200px; }
  margin-top: 50px; 
  margin-bottom: 50px; 
  padding: 0px 25px;
  @media (max-width: $tablet){
    padding: 0px;
  }
}