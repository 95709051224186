.GithubBadge {
    border: 2px solid #000;
    padding: 25px;
    &__Preview {
        table {
            border: 1px solid #ccc;
            padding: 0px;
            border-spacing: 0px;
            width: auto;
            tbody {
                padding: 0px;
                tr {
                    td:nth-child(1) {
                        border-right: 1px solid #ccc;
                        text-align: center;
                        padding: 0px 15px;
                    }
                    td:nth-child(2) {
                        padding: 15px;
                        a { color: #5f5fff; }
                    }
                }
            }
        }
    }
    textarea {
        appearance: none;
        border: 1px solid black;
        width: 100%;
        resize: none;
    }
}