@import '../../../styles/variables';

.Navbar {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0px 50px;
  @media (max-width:$tablet) {
    padding: 0px 25px;
  }
  width: 100%;
  &__Brand {
    height: 60px; width: auto;
    &--Logo {
      img { height: 100%; }
    }
  }
  &__Desktop {
    margin-left: auto;
    display: none;
    @media (min-width: 890px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--Item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 80px;
      padding: 0px 15px;
    }
  }
  &__Mobile {
    position: fixed;
    padding: 75px 25px 25px 25px;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0;
    z-index: -1;
    transition: all .1s linear;
    &--Contents {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &--Item {
        font-size: 1.25em;
        margin-top: 25px;
        color: white;
      }
    }
    &--Open {
      opacity: 1;
      z-index: 99;
      pointer-events: all;
    }
  }
}

// Old -- Refactoring

.navbar__hamburger {
  position: absolute;
  top: 29px;
  right: 25px;
  z-index: 100;
  cursor: pointer;
  &.is-active {
    position: fixed;
  }
}

.navbar__hamburger,
.Navbar__Mobile {
  display: block;
  @media (min-width: 890px) {
    display: none;
  }
}
