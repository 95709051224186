.Header {
  height: auto;
  background-color: white;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100% - 50px);
  border-bottom: 2px solid black;
  position: relative;
}

.Logo {
  margin-right: auto;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    img { height: 60px; margin-right: 5px;  }
    .Header__title {
      color: black;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 2em;
      font-weight: 300;
      @media (max-width: 889px) {
        font-size: 1.5em;
      }
    }
  }
  @media (max-width: 889px) {
    // flex-basis: 75%;
  }
}

.Navigation--Desktop {
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > *:not(first-child) {
    margin-left: 50px;
  }
}

.Nonet {
  display: flex;
  align-items: center;
}

.Nonet:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 5px;
  background-color: red;
}

.Rinkeby:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 5px;
  background-color: #5f5fff;
}

.Mainnet:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 5px;
  background-color: limegreen;
}

