@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.BotCard {
  border: 2px solid black;
  position: relative; }
  .BotCard__Header {
    padding: 10px 25px;
    border-bottom: 2px solid black; }
    .BotCard__Header--Name {
      width: 100%;
      text-transform: capitalize; }
    .BotCard__Header--Details {
      text-transform: capitalize;
      font-size: 0.85em;
      margin-top: -10px; }
  .BotCard img {
    width: 100%;
    height: auto !important; }
  .BotCard__Footer {
    border-top: 2px solid black;
    padding: 10px 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .BotCard__Footer--Type {
      text-transform: capitalize; }
  .BotCard .Verified {
    position: absolute;
    top: 20px;
    right: 15px; }
    .BotCard .Verified img {
      width: 24px; }

.iconFork {
  position: absolute;
  top: 50px;
  right: 15px; }
  .iconFork svg {
    fill: red; }

.BotList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }
  .BotList > .BotItem {
    position: relative;
    -webkit-flex-basis: 33%;
            flex-basis: 33%;
    margin-bottom: 25px; }
    .BotList > .BotItem a .BotItem__Hover {
      opacity: 0;
      transition: all .15s linear;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      border-radius: 50px;
      background-color: #000;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .BotList > .BotItem a .BotItem__Hover svg path {
        fill: #fff; }
    .BotList > .BotItem a:hover .BotItem__Hover {
      opacity: 1; }
    @media (max-width: 1100px) {
      .BotList > .BotItem {
        -webkit-flex-basis: 50%;
                flex-basis: 50%; } }
    @media (max-width: 768px) {
      .BotList > .BotItem {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }

.BotList .SupportButtonHolder button {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; }

.BotList .WithdrawButtonHolder button {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; }

.BotFilter__filters {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border: 2px solid black;
  margin: 25px auto; }
  .BotFilter__filters .Title {
    width: calc(15% - 34px);
    padding: 15px;
    border-right: 2px solid black; }
    @media (max-width: 768px) {
      .BotFilter__filters .Title {
        width: 100%;
        border-right: none;
        border-bottom: 2px solid black;
        text-align: center; } }
  .BotFilter__filters .BotFilter__filters--Fields {
    width: 85%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center; }
    .BotFilter__filters .BotFilter__filters--Fields h4 {
      width: 15%;
      padding-left: 15px; }

.BotFilter__select {
  padding: 20px;
  width: 40%; }
  .BotFilter__select > * {
    outline: none; }
  @media (max-width: 768px) {
    .BotFilter__select {
      width: 100%; } }
  .BotFilter__select > div:first-of-type {
    border: 2px solid black !important;
    border-radius: 0px !important;
    outline: none !important; }
    .BotFilter__select > div:first-of-type:focus {
      border: 2px solid black;
      border-radius: 0px; }

.Button__holder {
  margin-bottom: 15px;
  text-align: center; }
  .Button__holder a {
    display: block; }

@media (max-width: 480px) {
  .generate-bot {
    width: 100%;
    margin: 15px 0px; } }

.Bots__Intro {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .Bots__Intro p {
    margin: 50px 0px;
    font-size: 1.25em; }
    @media (max-width: 480px) {
      .Bots__Intro p {
        margin: 25px 0px; } }

.BotVerification {
  border: 2px solid black; }

.BotDetail {
  padding-top: 50px; }
  .BotDetail__Info {
    text-align: left;
    padding-left: 25px;
    -webkit-flex-basis: 50%;
            flex-basis: 50%; }
    @media (max-width: 768px) {
      .BotDetail__Info {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        padding-left: 0px; } }
  .BotDetail__Verification-Badge {
    color: red; }
  .BotDetail__Actions {
    width: 100%;
    border: 2px solid black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .BotDetail__Actions--Support {
      width: calc(50% - 50px);
      padding: 25px;
      border-bottom: 2px solid black;
      border-right: 2px solid black; }
      @media (max-width: 768px) {
        .BotDetail__Actions--Support {
          border-right: 0px solid transparent;
          width: 100%; } }
    .BotDetail__Actions--Buidl {
      width: calc(50% - 52px);
      padding: 25px;
      border-bottom: 2px solid black; }
      @media (max-width: 768px) {
        .BotDetail__Actions--Buidl {
          width: 100%; } }
    .BotDetail__Actions--Owner {
      width: 100%;
      padding: 25px; }
    .BotDetail__Actions button {
      width: 100%; }
  .BotDetail a {
    text-decoration: underline; }

.BotDetail__Card .HatchButton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 1; }
  .BotDetail__Card .HatchButton button {
    padding: 25px !important;
    width: 100%; }

.BotDetail__Info .HatchButton {
  position: relative; }
  .BotDetail__Info .HatchButton button {
    padding: 25px !important;
    width: 100%;
    max-width: 180px; }

.BotDetail .Ancestry {
  margin-bottom: 25px; }
  .BotDetail .Ancestry a {
    display: inline-block;
    margin-right: 10px; }

.Bot__Loader {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  background: #fff;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.GithubBadge {
  border: 2px solid #000;
  padding: 25px; }
  .GithubBadge__Preview table {
    border: 1px solid #ccc;
    padding: 0px;
    border-spacing: 0px;
    width: auto; }
    .GithubBadge__Preview table tbody {
      padding: 0px; }
      .GithubBadge__Preview table tbody tr td:nth-child(1) {
        border-right: 1px solid #ccc;
        text-align: center;
        padding: 0px 15px; }
      .GithubBadge__Preview table tbody tr td:nth-child(2) {
        padding: 15px; }
        .GithubBadge__Preview table tbody tr td:nth-child(2) a {
          color: #5f5fff; }
  .GithubBadge textarea {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 1px solid black;
    width: 100%;
    resize: none; }

.ArmyBot {
  display: inline-block;
  text-align: center;
  border: 2px solid black;
  margin-bottom: 25px;
  margin-right: 20px;
  width: 200px; }

.ArmyBotCard__Header {
  padding: 5px 5px;
  border-bottom: 2px solid black; }

.ArmyBotCard__Header p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px; }

.BotCard__Footer {
  padding: 5px 5px; }

.BotCard__Footer p {
  padding: 5px 5px;
  margin: 0px; }

.ArmyList {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 480px) {
    .ArmyList {
      text-align: center; } }



table {
  width: 100%; }
  table thead tr {
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    padding-top: 10px; }
  table th {
    text-align: left; }

.RepoDetail__Lists {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

@media (max-width: 768px) {
  .About h1 {
    font-size: 1.25em;
    padding: 15px; } }

.About a {
  text-decoration: underline; }

.Dashboard .Columns__Column--50:first-of-type > p {
  padding-right: 25px; }
  @media (max-width: 768px) {
    .Dashboard .Columns__Column--50:first-of-type > p {
      padding-right: 0px; } }

.Dashboard {
  padding-top: 50px; }

.DashboardBots .BotList a {
  -webkit-flex-basis: 100%;
          flex-basis: 100%; }

.DashboardBots .WithdrawButtonHolder button {
  width: 100%; }

@media (max-width: 480px) {
  .generate-bot {
    width: 100%;
    margin: 15px 0px; } }

.Hero a {
  color: white;
  font-size: 1.25em; }

.Hero .Columns {
  max-width: 1200px;
  margin: 0 auto; }
  .Hero .Columns__Column--50:first-of-type {
    -webkit-flex-basis: calc(45% - 100px);
            flex-basis: calc(45% - 100px);
    padding: 0px 50px; }
    @media (max-width: 1199px) {
      .Hero .Columns__Column--50:first-of-type {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        padding: 50px; } }
    @media (max-width: 768px) {
      .Hero .Columns__Column--50:first-of-type {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        padding: 25px; } }
  @media (max-width: 1199px) {
    .Hero .Columns__Column--50:nth-of-type(2) {
      -webkit-flex-basis: 100%;
              flex-basis: 100%;
      padding: 25px; } }

@media (max-width: 1199px) {
  iframe {
    margin: 0 auto;
    text-align: center;
    width: 100%; } }

@media (max-width: 768px) {
  iframe {
    width: 100%; } }

.Bots__Intro {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 50px; }
  .Bots__Intro p {
    margin: 25px 0px;
    font-size: 1.35em; }
    @media (max-width: 480px) {
      .Bots__Intro p {
        margin: 25px 0px; } }

.FeaturedBotList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }
  .FeaturedBotList > .BotItem {
    position: relative;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 2px solid #000; }
    .FeaturedBotList > .BotItem .Columns {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      @media (max-width: 768px) {
        .FeaturedBotList > .BotItem .Columns {
          -webkit-flex-direction: column;
                  flex-direction: column; } }
    .FeaturedBotList > .BotItem .Columns__Column--50:nth-of-type(2) {
      display: -webkit-flex;
      display: flex;
      -webkit-align-content: center;
              align-content: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media (max-width: 768px) {
        .FeaturedBotList > .BotItem .Columns__Column--50:nth-of-type(2) {
          -webkit-justify-content: center;
                  justify-content: center; } }
    .FeaturedBotList > .BotItem .BotCard {
      max-width: 320px;
      max-height: 250px;
      overflow: hidden; }
      @media (max-width: 768px) {
        .FeaturedBotList > .BotItem .BotCard {
          max-width: 100%;
          max-height: 320px; } }
    .FeaturedBotList > .BotItem a {
      position: relative; }
    .FeaturedBotList > .BotItem a .BotItem__Hover {
      opacity: 0;
      transition: all .15s linear;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      border-radius: 50px;
      background-color: #000;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .FeaturedBotList > .BotItem a .BotItem__Hover svg path {
        fill: #fff; }
    .FeaturedBotList > .BotItem a:hover .BotItem__Hover {
      opacity: 1; }
    @media (max-width: 768px) {
      .FeaturedBotList > .BotItem {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }

.SupportButtonHolder button {
  position: relative;
  display: block;
  height: 60px;
  width: auto; }

.WithdrawButtonHolder button {
  position: relative;
  display: block;
  height: 60px;
  width: auto; }

.Hero {
  background-color: #000;
  padding-top: 75px;
  padding-bottom: 75px;
  color: white; }
  .Hero .Columns__Column--50 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center; }

.Featured__Intro {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px 25px; }
  .Featured__Intro a {
    display: block;
    width: 200px; }
  @media (max-width: 768px) {
    .Featured__Intro {
      padding: 0px; } }

.Header {
  height: auto;
  background-color: white;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100% - 50px);
  border-bottom: 2px solid black;
  position: relative; }

.Logo {
  margin-right: auto; }
  .Logo a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .Logo a img {
      height: 60px;
      margin-right: 5px; }
    .Logo a .Header__title {
      color: black;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 2em;
      font-weight: 300; }
      @media (max-width: 889px) {
        .Logo a .Header__title {
          font-size: 1.5em; } }

.Navigation--Desktop {
  margin-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .Navigation--Desktop > *:not(first-child) {
    margin-left: 50px; }

.Nonet {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.Nonet:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 5px;
  background-color: red; }

.Rinkeby:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 5px;
  background-color: #5f5fff; }

.Mainnet:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 5px;
  background-color: limegreen; }

.Navbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
  padding: 0px 50px;
  width: 100%; }
  @media (max-width: 768px) {
    .Navbar {
      padding: 0px 25px; } }
  .Navbar__Brand {
    height: 60px;
    width: auto; }
    .Navbar__Brand--Logo img {
      height: 100%; }
  .Navbar__Desktop {
    margin-left: auto;
    display: none; }
    @media (min-width: 890px) {
      .Navbar__Desktop {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; } }
    .Navbar__Desktop--Item {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      position: relative;
      height: 80px;
      padding: 0px 15px; }
  .Navbar__Mobile {
    position: fixed;
    padding: 75px 25px 25px 25px;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0;
    z-index: -1;
    transition: all .1s linear; }
    .Navbar__Mobile--Contents {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-end;
              align-items: flex-end; }
      .Navbar__Mobile--Contents--Item {
        font-size: 1.25em;
        margin-top: 25px;
        color: white; }
    .Navbar__Mobile--Open {
      opacity: 1;
      z-index: 99;
      pointer-events: all; }

.navbar__hamburger {
  position: absolute;
  top: 29px;
  right: 25px;
  z-index: 100;
  cursor: pointer; }
  .navbar__hamburger.is-active {
    position: fixed; }

.navbar__hamburger,
.Navbar__Mobile {
  display: block; }
  @media (min-width: 890px) {
    .navbar__hamburger,
    .Navbar__Mobile {
      display: none; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 0px;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  outline: none;
  overflow: visible; }
  @media (min-width: 890px) {
    .hamburger {
      display: none; } }
  @media (max-width: 889px) {
    .hamburger {
      display: inline-block; } }
  .hamburger:hover {
    opacity: 1; }
    .hamburger:hover .hamburger-inner, .hamburger:hover .hamburger-inner::before, .hamburger:hover .hamburger-inner::after {
      background-color: black; }

.hamburger-box {
  width: 28px;
  height: 21px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 28px;
    height: 3px;
    background-color: black;
    border-radius: 2px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -9px; }
  .hamburger-inner::after {
    bottom: -9px; }

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner,
.hamburger--spin.is-active .hamburger-inner::before,
.hamburger--spin.is-active .hamburger-inner::after {
  background-color: white; }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.FeedbackBar {
  display: -webkit-flex;
  display: flex;
  background-color: black;
  color: white;
  padding: 10px 25px; }
  .FeedbackBar__Status {
    width: 100px;
    border: 2px solid white;
    border-radius: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .FeedbackBar__Status {
        display: none; } }
  .FeedbackBar__Info {
    margin-left: 15px; }
  .FeedbackBar a {
    color: white;
    text-decoration: underline; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Share Tech Mono', monospace;
  background-color: white;
  color: black;
  font-weight: 400; }

.App-logo {
  height: 40vmin; }

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin); }

.App-link {
  color: #61dafb; }

.Link {
  text-decoration: underline; }

a {
  color: black;
  text-decoration: none;
  transition: all .15s linear; }
  a:hover {
    color: #5f5fff; }

.PageHeader {
  min-height: 100vh;
  width: 100%;
  background: black;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center; }
  .PageHeader__Contents {
    max-width: 800px;
    margin: 0 auto; }

.Block {
  min-height: 50vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .Block__Contents {
    max-width: 800px;
    margin: 0 auto; }
  .Block.Black {
    background-color: black;
    color: white !important; }

.Border-Bottom {
  border-bottom: 2px solid black; }

.Box {
  border: 2px solid black;
  padding: 15px; }

.Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 99; }
  .Loader__Contents {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 600px;
    padding: 50px;
    border: 2px solid #fff; }

.Columns {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around; }
  .Columns__Column--33 {
    position: relative;
    height: 100%;
    -webkit-flex-basis: 30%;
            flex-basis: 30%; }
    @media (max-width: 768px) {
      .Columns__Column--33 {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }
  .Columns__Column--50 {
    -webkit-flex-basis: 45%;
            flex-basis: 45%;
    height: 100%;
    position: relative; }
    @media (max-width: 768px) {
      .Columns__Column--50 {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }

.Capitalize, .Stats {
  text-transform: capitalize; }

button:not(.navbar__hamburger):not(.Link), .button:not(.navbar__hamburger) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: black;
  color: white;
  padding: 10px 25px;
  font-size: 1em;
  outline: none;
  border: 2px solid transparent;
  transition: all .15s linear;
  font-family: "Share Tech Mono", monospace;
  cursor: pointer; }
  button:not(.navbar__hamburger):not(.Link):hover, .button:not(.navbar__hamburger):hover {
    background: #5F5FFF;
    color: white; }

button.Link {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: transparent;
  color: white;
  padding: 0px;
  outline: none;
  border: none;
  transition: all .15s linear;
  font-family: "Share Tech Mono", monospace;
  cursor: pointer; }
  button.Link:hover {
    color: #5F5FFF;
    background: transparent; }

form {
  padding: 25px;
  border: none;
  border: 2px solid black; }

label {
  display: block; }

input {
  background-color: transparent;
  -webkit-rtl-ordering: logical;
  cursor: text;
  padding: -px;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
  border-image: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  width: 100%;
  font-size: 1.35em; }
  input:focus {
    outline: none;
    border-bottom: 1px solid #5f5fff; }

fieldset {
  display: block;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-before: 0px;
          padding-block-start: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  -webkit-padding-end: 0px;
          padding-inline-end: 0px;
  -webkit-padding-after: 0px;
          padding-block-end: 0px;
  min-inline-size: -webkit-min-content;
  min-inline-size: -moz-min-content;
  min-inline-size: min-content;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
  border-image: none; }

.Contain {
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .Contain {
      width: calc(100% -  50px);
      padding: 25px; } }

pre {
  white-space: pre-wrap;
  font-size: 11px;
  border-left: 2px solid #000;
  padding: 15px; }

.noWeb3 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  z-index: 99; }
  @media (max-width: 768px) {
    .noWeb3 {
      width: calc(100vw - 50px);
      padding: 0px 25px; } }
  .noWeb3__Contents {
    border: 2px solid white;
    padding: 25px;
    position: relative; }
    .noWeb3__Contents a {
      color: #5f5fff; }
    .noWeb3__Contents button {
      position: absolute;
      top: -44px;
      right: -4px;
      border: 2px solid white !important; }

