.BotCard {
  border: 2px solid black;
  position: relative;
  &__Header {
    padding: 10px 25px;
    border-bottom: 2px solid black; 
    &--Name { 
      width: 100%;
      text-transform: capitalize;
    }
    &--Details {
      text-transform: capitalize;
      font-size: 0.85em;
      margin-top: -10px;
    }
  }
  img {
    width: 100%;
    height: auto !important;
  }
  &__Footer {
    border-top: 2px solid black;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    &--Type {
      text-transform: capitalize;
    }
  }
  .Verified {
    position: absolute;
    top: 20px;
    right: 15px;
    img { width: 24px; }
  }
}

.iconFork {
  position: absolute;
  top: 50px;
  right: 15px;
  svg {fill:red;}
}
