@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono');
@import './styles/variables';

body {
  margin: 0;
  padding: 0;
  font-family: 'Share Tech Mono', monospace;
  background-color: white;
  color: black;
  font-weight: 400;
}

.App {
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Link {
  text-decoration: underline;
}

a {
  color: black;
  text-decoration: none;
  transition: all .15s linear;
  &:hover {
    color: #5f5fff;
  }
}

.PageHeader {
  min-height: 100vh;
  width: 100%;
  background: black;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__Contents {
    max-width: 800px;
    margin: 0 auto;
  }
}

.Block {
  min-height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__Contents {
    max-width: 800px;
    margin: 0 auto;
  }
  &.Black {
    background-color: black;
    color: white !important;
  }
}

.Border-Bottom {
  border-bottom: 2px solid black;
}

.Box {
  border: 2px solid black;
  padding: 15px;
}

// Components

.Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  &__Contents {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 50px;
    border: 2px solid #fff; 
  }
}

// Columns

.Columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  &__Column {
    &--33 {
      position: relative;
      height: 100%;
      flex-basis: 30%;
      @media (max-width: $tablet) {
        flex-basis: 100%;
      }
    }
    &--50 {
      flex-basis: 45%;
      height: 100%;
      position: relative;
      @media (max-width: $tablet) {
        flex-basis: 100%;
      }
    }
  }
}


// Utils

.Capitalize, .Stats {
  text-transform: capitalize;
}

button:not(.navbar__hamburger):not(.Link), .button:not(.navbar__hamburger) {
  appearance: none;
  border: none;
  background: black;
  color: white;
  padding: 10px 25px;
  font-size: 1em;
  outline: none;
  border: 2px solid transparent;
  transition: all .15s linear;
  font-family: $base-font;
  cursor: pointer;
  &:hover {
    background: #5F5FFF;
    color: white;
  }
}

button.Link {
  appearance: none;
  border: none;
  background: transparent;
  color: white;
  padding: 0px;
  outline: none;
  border: none;
  transition: all .15s linear;
  font-family: $base-font;
  cursor: pointer;
  &:hover {
    color: #5F5FFF;
    background: transparent;
  }
}

// Forms

form {
  padding: 25px;
  border: none;
  border: 2px solid black;
}

label {
  display: block;
}

input {
  background-color: transparent;
  -webkit-rtl-ordering: logical;
  cursor: text;
  padding: -px;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
  border-image: none;
  appearance: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  width: 100%;
  font-size: 1.35em;
  &:focus { outline: none; border-bottom: 1px solid #5f5fff; }
}

fieldset {
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-end: 0px;
  min-inline-size: min-content;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
  border-image: none;
}

.Contain {
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: $tablet) {
    width: calc(100% -  50px);
    padding: 25px;
  }
}

// Pre Code

pre {
  white-space: pre-wrap;
  font-size: 11px;
  border-left: 2px solid #000;
  padding: 15px; 
  code {

  }
}

// Error

.noWeb3 { 
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 99;
  @media (max-width: $tablet) {
    width: calc(100vw - 50px);
    padding: 0px 25px;
  }
  &__Contents {
    border: 2px solid white;
    padding: 25px;
    position: relative;
    a {
      color: #5f5fff;
    }
    button { 
      position: absolute; 
      top: -44px; 
      right: -4px; 
      border: 2px solid white !important;
     }
  }
}