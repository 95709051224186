@import '../../../styles/variables';

.BotList {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  > .BotItem {
    position: relative;
    flex-basis: 33%;
    margin-bottom: 25px;
    a .BotItem__Hover {
      opacity: 0;
      transition: all .15s linear;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      border-radius: 50px;
      background-color: #000;
      svg path { fill: #fff; }
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a:hover .BotItem__Hover {
      opacity: 1;
    }
    @media (max-width: 1100px) {
      flex-basis: 50%;
    }
    @media (max-width: $tablet) {
      flex-basis: 100%;
    }
  }
}

.BotList .SupportButtonHolder button {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}
.BotList .WithdrawButtonHolder button {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}
.WithdrawButtonHolder {
}
.WithdrawButtonHolder p {
}