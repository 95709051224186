.ArmyList {
    // display: flex;
    // flex-direction: row;
    // align-content: flex-start;
    // justify-content: space-around;
    // flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 480px){
        text-align: center;
    }
}