@import '../../../styles/variables';

.FeedbackBar {
    display: flex;
    background-color: black;
    color: white;
    padding: 10px 25px;
    &__Status {
        width: 100px;
        border: 2px solid white;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        @media (max-width: $tablet) {
            display: none;
        }
    }
    &__Info {
        margin-left: 15px;
    }
    a { color: white; text-decoration: underline; }
}