table {
    width: 100%;
    thead {
        
        tr {
            border-bottom: 1px solid #efefef;
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }
    th {
        text-align: left;
    }
}