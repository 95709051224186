@import '../../styles/variables';

.Dashboard .Columns__Column--50:first-of-type > p {
    padding-right: 25px;
    @media (max-width: $tablet) {
      padding-right: 0px;
    }
}

.Dashboard {
  padding-top: 50px;
}
